import React from "react"
import { Link } from "gatsby"
import downsize from "downsize"

export const PostList = ({ posts }) => {
  return (
    <div>
      {posts.map(({ node }) => {
        const paragraphs = node.blocks
          .filter(block => block.name === "core/paragraph")
          .map(block => block.saveContent)
          .join("\n")
        return (
          <div className="no-underline" key={node.date}>
            <Link to={node.uri} className="no-underline">
              <small className="underline">
                {new Date(node.date).toLocaleDateString("se-SE")}
              </small>
              <h2 className="mt-0 mb-0">{node.title} </h2>
              <small>
                {node.postFieldGroup.author && (
                  <>
                    Av:{" "}
                    {node.postFieldGroup.author
                      .map(({ authorFields }) => authorFields.name)
                      .join(", ")}
                  </>
                )}
              </small>
              {paragraphs.length && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: downsize(paragraphs, {
                      words: 20,
                      append: "...",
                    }),
                  }}
                  className="mb-8"
                ></div>
              )}
            </Link>
          </div>
        )
      })}
    </div>
  )
}
